import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useAnnouncementsStore = defineStore("announcementsStore", () => {
  const announcements = ref([]);
  const search = ref("");
  const loadingAnnouncements = ref(false);
  const currentPage = ref(1)
  const pageSize = ref(10);
  const totalAnnouncements = ref(0);
  const archiveDate = ref(null)
  const announcement = ref(null);
  const loadingAnnouncement = ref(false);
  const loadingDelete = ref(false);
  const selectedAnnouncementId = ref(null);
  const isHomePage = ref(false);

  const params = computed(() => {
    return {
      page: currentPage.value,
      limit: pageSize.value,
      keyword: search.value,
      archive_date: archiveDate.value,
      is_home_page: isHomePage.value,
    }
  })

  const handleResetFilters = () => {
    search.value = "";
    currentPage.value = 1;
    archiveDate.value = null;
    pageSize.value = 10;
    isHomePage.value = false;
  }

  const handleFetchAnnouncements = async () => {
    try {
      loadingAnnouncements.value = true;
      const { data } = await http().get("v3/announcements", {
        params: params.value,
      });
      announcements.value = data.data.data;
      totalAnnouncements.value = data.data.total

    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingAnnouncements.value = false;
    }
  };

  const handleFetchAnnouncement = async (announcementId) => {
    try {
      loadingAnnouncement.value = true;
      const { data } = await http().get(`v3/announcements/${announcementId}`);
      announcement.value = data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingAnnouncement.value = false;
    }
  };

  const handleDeleteAnnouncement = async () => {
    try {
      loadingDelete.value = true;
      await http().delete(`v3/announcements/${selectedAnnouncementId.value}`)
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingDelete.value = false;
    }
  }

  return {
    announcements,
    loadingAnnouncements,
    archiveDate,
    announcement,
    loadingAnnouncement,
    search,
    currentPage,
    pageSize,
    params,
    totalAnnouncements,
    loadingDelete,
    selectedAnnouncementId,
    isHomePage,
    handleFetchAnnouncements,
    handleFetchAnnouncement,
    handleDeleteAnnouncement,
    handleResetFilters,
  };
});
