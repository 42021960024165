import { registerModules } from "./registerModules";

import LmsSettings from "./modules/LmsSettings";
import ScormCourse from "./modules/ScormCourse";
import CustomPlaylist from "./modules/CustomPlaylist";
import Events from "./modules/Events";
import Announcement from "./modules/Announcement";

registerModules({
  lmsSettings: LmsSettings,
  scormCourse: ScormCourse,
  customePlaylist: CustomPlaylist,
  Events,
  Announcement
});
