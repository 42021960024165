const AnnouncementModule = () => import('./AnnouncementModule.vue');

const moduleRoute = {
  path: "/news-feed",
  component: AnnouncementModule,
  props: true,
  children: [
    {
      path: '',
      component: () => import('./views/AnnouncementHomePage.vue'),
      name: 'Announcement Home Page',
      props: true,
      meta: {
        title: "News Feed",
        auth: true,
      },
    },
    {
      path: ':id',
      name: 'Announcement Details',
      component: () => import('./views/AnnouncementViewPage.vue'),
      props: true,
      meta: {
        title: "News Feed Details",
        auth: true,
      },
    }
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
