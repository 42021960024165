<template>
  <div v-bind="$attrs">
    <el-popover
      v-if="folders.length"
      :width="220"
      popper-style="padding: 5px !important;z-index: 3000 !important"
    >
      <template #reference>
        <a
          class="text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 border-b-2 hover:text-untitled-gray-700 px-1 pt-1 text-sm w-full h-full flex justify-center items-center font-medium"
          href="/resource-manager"
        >
          Resource Manager
        </a>
      </template>
      <template #default>
        <div v-if="loading">
          <h3>Loading...</h3>
        </div>
        <div v-else class="w-full flex flex-col justify-start items-start">
          <div class="w-full" v-for="folder in folders" :key="folder.id">
            <a
              :href="'/resource-manager/' + folder.id"
              target="_blank"
              class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
              >{{ folder.name }}</a
            >
          </div>
        </div>
      </template>
    </el-popover>
    <a
      v-else
      class="text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 border-b-2 hover:text-untitled-gray-700 px-1 pt-1 text-sm w-full h-full flex justify-center items-center font-medium"
      href="/resource-manager"
    >
      Resource Manager
    </a>
  </div>
</template>
<script setup>
import Bugsnag from "@bugsnag/js";
import { ElPopover } from "element-plus";
import { ref, inject, onMounted } from "vue";

const axios = inject("axios");

const loading = ref(true);
const folders = ref([]);

const getFolders = async () => {
  try {
    const { data } = await axios.get("/api/v3/file-manager/folders");
    folders.value = data.splice(0, 20);

    loading.value = false;
  } catch (error) {
    loading.value = false;
    Bugsnag.notify(error);
  }
};

onMounted(async () => {
  await getFolders();
});
</script>
<style lang="scss" scoped></style>
