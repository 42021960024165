<template>
  <el-dialog
    v-model="openDialog"
    :before-close="handleClose"
    :show-close="showClose"
    :width="widthSize"
    :fullscreen="fullscreen"
    :center="center"
    :align-center="alignCenter"
  >
    <template #header="{ titleId, titleClass }">
      <div :class="[titleCenter ? '' : 'flex justify-start items-center']">
        <h3
          :id="titleId"
          :class="titleClass"
          class="text-base font-semibold leading-2 text-gray-900"
        >
          {{ title }}
        </h3>
      </div>
    </template>
    <slot name="content"></slot>

    <template #footer v-if="showActionButton">
      <span class="flex">
        <div class="grow" />
        <button
          :disabled="isLoading"
          :class="[
            disabled || isLoading ? 'cursor-not-allowed' : '',
            'rounded shadow px-4 mr-2 py-2 flex justify-center items-center text-sm font-semibold text-untitled-gray-900 shadow-sm bg-untitled-gray-100 hover:bg-untitled-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-untitled-gray-600',
          ]"
          @click="handleClickCancel"
        >
          {{ cancelLabel }}
        </button>
        <button
          :disabled="isLoading || disabled"
          :class="[
            disabled ? 'cursor-not-allowed' : '',
            'rounded border shadow border-untitled-gray-400 px-4 py-2 flex justify-center items-center text-sm font-semibold text-untitled-gray-900 shadow-sm hover:bg-untitled-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-untitled-gray-600',
          ]"
          @click="handleClickCSubmit"
        >
          {{ isLoading ? `${loadingLabel}...` : submitLabel }}
        </button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { computed, defineProps, defineEmits, ref, onMounted, watch } from "vue";
import { ElDialog, ElMessageBox } from "element-plus";
import { useDialogStore } from "@/stores/useDialogStore";
const dialogStore = useDialogStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Title",
  },
  cancelLabel: {
    type: String,
    default: "Cancel",
  },
  submitLabel: {
    type: String,
    default: "Submit",
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  showActionButton: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  loadingLabel: {
    type: String,
    default: "Loading",
  },
  dialogDefaultSize: {
    type: String,
    default: "50%",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  titleCenter: {
    type: Boolean,
    default: true,
    required: false,
  },
  forceClose: {
    type: Boolean,
    required: false,
  },
  uncloseAble: {
    type: Boolean,
    required: false,
  },
  hasInput: {
    type: Boolean,
    default: false,
    required: false,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  alignCenter: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "onCancel", "onSubmit"]);

const openDialog = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const title = computed(() => props.title);
const submitLabel = computed(() => props.submitLabel);
const cancelLabel = computed(() => props.cancelLabel);
const showClose = computed(() => props.showClose);
const showActionButton = computed(() => props.showActionButton);
const isLoading = computed(() => props.isLoading);
const loadingLabel = computed(() => props.loadingLabel);
const windowSize = ref(window.innerWidth);
const dialogSize = ref("75%");
const widthSize = computed(() => dialogSize.value);
const disabled = computed(() => props.disabled);
const forceClose = computed(() => props.forceClose);
const hasInput = computed(() => props.hasInput);
const uncloseAble = computed(() => props.uncloseAble);

const handleClose = (done) => {
  if (uncloseAble.value) {
    return;
  }

  if (!hasInput.value) {
    emit("onCancel");
    done();
    return;
  }

  if (forceClose.value) {
    emit("onCancel");
    done();
    dialogStore.hideDialog();
    return;
  }
  ElMessageBox.confirm("Are you sure you want to close this?", {
    cancelButtonText: "No",
    cancelButtonClass: "bg-yellow",
    confirmButtonText: "Yes",
    confirmButtonClass: "dialogCloseOkButtonClass",
  }).then(() => {
    dialogStore.hideDialog();
    emit("onCancel");
    done();
  });
};

const handleClickCancel = () => {
  dialogStore.hideDialog();
  openDialog.value = false;
  emit("onCancel");
};

const handleClickCSubmit = () => {
  dialogStore.hideDialog();
  emit("onSubmit");
};

const updateScreenSize = () => {
  windowSize.value = window.innerWidth;
  if (windowSize.value < 768) {
    dialogSize.value = "100%";
    return;
  } else if (windowSize.value < 1024) {
    dialogSize.value = "70%";
    return;
  } else {
    dialogSize.value = props.dialogDefaultSize;
    return;
  }
};

onMounted(() => {
  windowSize.value = window.innerWidth;
  updateScreenSize();
  window.addEventListener("resize", updateScreenSize);
  dialogSize.value = props.dialogDefaultSize;
});

watch(windowSize, () => updateScreenSize);
</script>
<style>
.dialogCloseOkButtonClass {
  background-color: rgb(107 114 128) !important;
  color: #fff !important;
}

.dialogCloseOkButtonClass:focus {
  border: rgb(55 65 81) !important;
}

.dialogCloseOkButtonClass:hover {
  border: rgb(55 65 81) !important;
  background-color: rgb(156 163 175) !important;
  color: #fff !important;
}
</style>
