<template>
  <div class="w-full h-fit pb-10 lg:sticky top-20 flex flex-col gap-3">
    <RightSideTabs />
    <KeepAlive>
      <component :is="selectedComponent" />
    </KeepAlive>
    <div class="w-full border-t border-untitled-gray-300" />
    <FeaturedVideo />
    <HomeAnnouncements />
    <div class="w-full border-t border-untitled-gray-300" />
    <div class="bg-white">
      <GroupListSide></GroupListSide>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import RightSideTabs from "./partials/RightSideTabs.vue";
import { useHomeTabsStore } from "@/stores/home/useHomeTabs.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import GroupListSide from "../groups/GroupListSide.vue";
import HomeAnnouncements from "../home/announcements/HomeAnnouncements.vue";

const HomeReportSummary = defineAsyncComponent(() =>
  import("./HomeReportSummary.vue")
);

const HomeMyVideos = defineAsyncComponent(() =>
  import("./my-videos/HomeMyVideos.vue")
);

const HomeEventsAndReminders = defineAsyncComponent(() =>
  import("./events-and-reminders/HomeEventsAndReminders.vue")
);

const FeaturedVideo = defineAsyncComponent(() =>
  import("./featured-video/FeaturedVideo.vue")
);

const homeTabStore = useHomeTabsStore();
const { selectedRightSectionTab } = storeToRefs(homeTabStore);

const selectedComponent = computed(() => {
  if (selectedRightSectionTab.value === "report_summary") {
    return HomeReportSummary;
  }
  if (selectedRightSectionTab.value === "my_videos") {
    return HomeMyVideos;
  }
  return HomeEventsAndReminders;
});
</script>

<style lang="scss" scoped></style>
